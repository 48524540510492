import NavigationBar from "./Navbar";
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs";
import ContactUs from "./Pages/ContactUs";
import ProductPage from "./Pages/ProductPage";
import "./App.css";
import Footer from "./component/Footer";
import SignUp from "./Pages/SignUp";
import EditProfile from "./Pages/EditProfile";
import ChangePassword from "./Pages/ChangePassword";
import PageNotFound from "./Pages/PageNotFound/PageNotFound";
import { useDispatch, useSelector } from "react-redux";
// import { getCategoryList } from "./Store/Actions/product";
import { createGlobalStyle } from "styled-components";
import axios from "axios";
import { baseURL } from "./Store/Actions";
import { datauser } from "./Store/Actions/user";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";

let category_list = {};
let linkName = "";

const App = () => {
  const dispatch = useDispatch();
  const [fullName, setFullName] = useState("");
  const [tokenlogin, setTokenLogin] = useState("");
  const [link, setLink] = useState("");

  const showCategoryList = async () => {
    await getCategoryList();
  };
  const getCategoryList = async () => {
    // getAPI
    try {
      const response = await axios.get(baseURL + "/category");
      category_list = response.data.result;
      // localStorage.setItem('categoryDataList', JSON.stringify(category_list));
      setLink(category_list[0].link_name);
      // linkName = category_list[0].link_name;
    } catch (e) {
      // console.error(e.message);
      // console.error(e)
      // console.error(e.code)
      const error = e.code
      if (error === "ERR_NETWORK"){
        console.clear()
        console.log(error)
        console.log("navigate to maintanence")
        // belum bisa navigate
        // return(
        //   <Navigate to="*" />
        // )
      }      
    }
  };

  const getDataUser = async (id) => {
    dispatch(datauser(id, setFullNameQ, setEmailQ, setPhone));
  };

  const setFullNameQ = (fullName) => {
    // console.log("Full Name:", fullName);
    localStorage.setItem("fullName", fullName);
    setFullName(fullName);
  };

  const setEmailQ = (email) => {
    // console.log("Email:", email);
    localStorage.setItem("email", email);
  };

  const setPhone = (phone) => {
    // console.log("Phone:", phone);
    localStorage.setItem("phone", phone);
  };

  useEffect(() => {
    const id = localStorage.getItem(`id`);
    getDataUser(id);
    showCategoryList();
    const storedFullName = localStorage.getItem("fullName");
    const loginToken = localStorage.getItem("token");

    if (storedFullName && loginToken) {
      setFullName(storedFullName);
      setTokenLogin(loginToken);
    }
  }, []);

  const productPath = `/product/${link}`;
  // const productPath =`/product/:category`
  const routes = [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "*",
      element: <PageNotFound />,
    },
    {
      path: "/product",
      element: <Navigate to={productPath} />,
    },
    {
      path: "/product/:category/:subcategory?",
      element: <ProductPage />,
    },
    {
      path: "/about-us",
      element: <AboutUs />,
    },
    {
      path: "/contact-us",
      element: <ContactUs />,
    },
    {
      path: "/sign-up",
      element: <SignUp />,
    },
    {
      path: "/edit-profile",
      element: <EditProfile updateNavbarFullName={setFullName} />,
    },
    {
      path: "/edit-profile/change-password",
      element: <ChangePassword />,
    },
    {
      path: "/privacypolicy",
      element: <PrivacyPolicy />,
    },
  ];
  return (
    <Router>
      <div className="App">
        <NavigationBar username={fullName} />
      </div>
      <Routes>
        {routes.map((route, idx) => (
          <Route key={idx} exact path={route.path} element={route.element} />
        ))}
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
