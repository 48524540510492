import { useState } from "react";
import { baseURL } from "../../Store/Actions";
import styles from "./image_slider.module.css";
import labaFontLogo from "../../Assets/Laba2.png";
import spiderFontLogo from "../../Assets/Spider.png";
import silstarFontLogo from "../../Assets/logo Silstar.png";
import carouselIndexButton from "../../Assets/ImageCarouselIndexButton.svg";
import carouselIndexButtonClicked from "../../Assets/ImageCarouselIndexButtonClicked.svg";
import rightVector from "../../Assets/rightVector.svg";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { useEffect } from "react";
import { useRef } from "react";
const ImageSlider = ({ imgs, width }) => {
  const timer = useRef(null);
  const loadTimer = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const [currIdx, setCurrIdx] = useState(0);
  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      // console.log("ran");
      imageInfiniteNext();
    }, 5000);

    loadTimer.current = setTimeout(() => {
      setLoaded(true);
    }, 50);

    return () => clearTimeout(timer.current);
  });

  const imageInfiniteNext = () => {
    if (currIdx === imgs.length - 1) {
      setCurrIdx(0);
      return;
    }
    const newIdx = currIdx + 1;
    setCurrIdx(newIdx);
  };

  // console.log(imgs);

  const getImg = (idx) => ({
    backgroundImage: `url(${baseURL}/${imgs[idx].image_home})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    width: `${width}px`,
  });

  const getImgContainerWithWidth = () => ({
    width: width * imgs.length,
    transform: `translateX(${-currIdx * width}px)`,
  });

  const leftNavigateHomePhotos = () => {
    const newIdx = currIdx - 1;
    if (newIdx < 0) {
      return;
    }
    setCurrIdx(newIdx);
  };

  const rightNavigateHomePhotos = () => {
    const newIdx = currIdx + 1;
    if (newIdx > imgs.length - 1) {
      return;
    }
    setCurrIdx(newIdx);
  };

  return loaded ? (
    <div className={styles.imageSliderContainer}>
      <div className={styles.imageCarouselButtonMobileContainer}>
        {currIdx > 0 ? (
          <BiChevronLeft
            className={styles.imageCarouselButtonMobile}
            onClick={leftNavigateHomePhotos}
          />
        ) : (
          <BiChevronLeft
            className={styles.imageCarouselButtonMobile}
            style={{ visibility: "hidden" }}
            onClick={leftNavigateHomePhotos}
          />
        )}
        {currIdx < imgs.length - 1 ? (
          <BiChevronRight
            className={styles.imageCarouselButtonMobile}
            onClick={rightNavigateHomePhotos}
          />
        ) : (
          <BiChevronRight
            className={styles.imageCarouselButtonMobile}
            style={{ visibility: "hidden" }}
            onClick={rightNavigateHomePhotos}
          />
        )}
      </div>
      <div style={getImgContainerWithWidth()} className={styles.imgContainer}>
        {imgs.map((img, idx) => {
          return <div key={idx} style={getImg(idx)}></div>;
        })}
      </div>
      <div className={`${styles.imageSlider}`}>
        <div className={styles.imageContainer}>
          <img className={styles.spiderFontLogo} src={spiderFontLogo} alt="" />
          <img className={styles.labaFontLogo} src={labaFontLogo} alt="" />
          <img
            className={styles.silstarFontLogo}
            src={silstarFontLogo}
            alt=""
          />
        </div>
        <div className={styles.imageCarouselButtonContainer}>
          {currIdx > 0 ? (
            <button
              className={styles.navigationBtnDesktop}
              onClick={leftNavigateHomePhotos}
            >
              <div
                className={styles.imageCarouselCircleButtonDirectionalContainer}
              >
                <img
                  className={styles.imageCarouselCircleButtonDirectional}
                  src={carouselIndexButton}
                  alt=""
                />
                <img
                  className={`${styles.imageCarouselCircleButtonDirectionalArrow} ${styles.leftArrow}`}
                  src={rightVector}
                  alt=""
                />
              </div>
            </button>
          ) : null}
          {imgs.map((e, idx) => {
            return currIdx !== idx ? (
              <button
                className={styles.navigationBtnDesktop}
                key={e.id}
                onClick={() => {
                  setCurrIdx(idx);
                }}
              >
                <img
                  className={styles.imageCarouselCircleButton}
                  src={carouselIndexButton}
                  alt=""
                />
              </button>
            ) : (
              <button
                style={{ border: "none", backgroundColor: "transparent" }}
                key={e.id}
                onClick={() => {
                  setCurrIdx(idx);
                }}
              >
                <img
                  className={styles.imageCarouselCircleButton}
                  src={carouselIndexButtonClicked}
                  alt=""
                />
              </button>
            );
          })}
          {currIdx < imgs.length - 1 ? (
            <button
              className={styles.navigationBtnDesktop}
              onClick={rightNavigateHomePhotos}
            >
              <div
                className={styles.imageCarouselCircleButtonDirectionalContainer}
              >
                <img
                  className={styles.imageCarouselCircleButtonDirectional}
                  src={carouselIndexButton}
                  alt=""
                />
                <img
                  className={styles.imageCarouselCircleButtonDirectionalArrow}
                  src={rightVector}
                  alt=""
                />
              </div>
            </button>
          ) : (
            <button
              className={styles.navigationBtnDesktop}
              style={{
                visibility: "hidden",
              }}
              onClick={() => {
                const newIdx = currIdx + 1;
                if (newIdx > imgs.length - 1) {
                  return;
                }
                setCurrIdx(newIdx);
              }}
            >
              <div
                className={styles.imageCarouselCircleButtonDirectionalContainer}
              >
                <img
                  className={styles.imageCarouselCircleButtonDirectional}
                  src={carouselIndexButton}
                  alt=""
                />
                <img
                  className={styles.imageCarouselCircleButtonDirectionalArrow}
                  src={rightVector}
                  alt=""
                />
              </div>
            </button>
          )}
        </div>
      </div>
    </div>
  ) : null;
};

export default ImageSlider;
