import React from "react";
import "../Pages/styleaboutus.css";
import { Container, Row, Col } from "react-bootstrap";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAboutPhoto, getAboutUs } from "../Store/Actions/company";
import { baseURL } from "../Store/Actions";
import Breadcrumb from "../component/Breadcrumb";

function AboutUs() {
  const dispatch = useDispatch();
  const { aboutphoto, about } = useSelector((state) => state.company);
  const aboutUsImage = `${baseURL}/${aboutphoto?.[0]?.data?.[0].image_about_us}`;
  const showAboutPhoto = async () => {
    try {
      dispatch(getAboutPhoto());
    } catch (e) {
      // console.log(e);
    }
  };
  const showAboutUs = async () => {
    try {
      dispatch(getAboutUs());
    } catch (e) {
      // console.log(e);
    }
  };
  useEffect(() => {
    showAboutUs();
    showAboutPhoto();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="AboutUsContent">
      <Breadcrumb />
      <Container fluid>
        <div className="DefaultTitlePage">ABOUT US</div>
        {!aboutphoto?.[0]?.data?.[0].image_about_us ? null : (
          <Row className="AboutUsImage">
            <div>
              <img src={aboutUsImage} alt="AboutUsImage.jpg" />
            </div>
          </Row>
        )}
        <Row>
          <Col lg={6} sm={12}>
            <div
              dangerouslySetInnerHTML={{ __html: about?.[0]?.data?.[0].about }}
            />
          </Col>
          <Col lg={6} sm={12}>
            <div
              className="right-boxed"
              dangerouslySetInnerHTML={{ __html: about?.[0]?.data?.[1].about }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default AboutUs;
